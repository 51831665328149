$primary-color: #1C3660;
$secondary-color: #ED6F1C;
$main-color: #F8F8F8;
$black-color: #000000;
$white-color: #ffffff;
$softblack-color: #1F1F1F;
$border-gray: rgb(207, 207, 207);
$linear-gradient: linear-gradient(90deg, rgba(237, 111, 28, 1) 0%, rgba(203, 99, 53, 1) 20%, rgba(75, 63, 92, 1) 70%, rgba(0, 0, 0, 1) 100%);

$small: 600px;
$medium: 768px;
$medium-l: 769px;
$large: 1024px;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@font-face {
    font-family: 'Rigo';
    src:
        url('../assets/fonts/Rigo.woff') format('woff2'),
        url('../assets/fonts/Rigo-Black.woff') format('woff2'),
        url('../assets/fonts/Rigo-Bold.woff') format('woff2');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: $black-color;
    font-family: "DM Sans", sans-serif;
    overflow-x: hidden;
    cursor: url('../assets/images/logo/amblem-svg-orange.svg'), auto;

    ::selection {
        background-color: $secondary-color;
    }
}

ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1360px;
    }
}

.primary-btn {
    background-color: $black-color;
    color: $main-color;
    border-radius: 12px;
    border: 2px solid $black-color;
    transition: all .3s ease;

    &:hover {
        background-color: $main-color;
        color: $black-color;
        border: 2px solid $black-color;
    }
}

.fs-92 {
    font-size: 92px;
}

.technologies_content {
    .tech-icon {
        height: 60px;
    }

    .homepage_section_title {
        font-size: 42px;
        letter-spacing: .4px;
        width: 50%;
        margin: auto;

        @media screen and (max-width: $medium) {
            width: 100%;
            font-size: 34px;
        }

        @media (min-width: $medium) and (max-width: $large) {
            width: 75%;
        }
    }
}

.homepage_section_title {
    font-size: 42px;
    letter-spacing: .4px;

    @media screen and (max-width: $medium) {
        font-size: 34px;
    }
}

.error-message {
    color: #b91e1e;
}

/* START BUTTONS */

.small_proposal_btn {
    background: $linear-gradient;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border-radius: 12px;
    border: 1px solid $black-color;
    padding: 5px 15px;
    cursor: pointer;
    background-size: 200%;
    background-position: left;
    transition: all 0.3s ease;
    overflow: hidden;
}

.proposal_btn {
    background: $linear-gradient;
    font-size: 22px;
    font-weight: 500;
    color: white;
    border-radius: 12px;
    border: 2px solid $black-color;
    padding: 10px 20px;
    cursor: pointer;
    background-size: 200%;
    background-position: left;
    transition: all 0.3s ease;
    overflow: hidden;

    @media screen and (max-width: $medium) {
        font-size: 20px;
    }
}

/* END BUTTONS */

/* START HEADER */

.mobile_header_content {
    background-color: $white-color;
    padding: 10px 0;
    display: none;

    @media screen and (max-width: $medium) {
        display: block;
    }

    .hamburger_icon {
        width: 35px;
        height: 35px;
        background: $linear-gradient;
        border: none;
        color: $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }

    .offcanvas-body {
        padding: 2rem 1.5rem;

        ul {
            a {
                text-decoration: none;
                color: $softblack-color;
                font-size: 20px;
                font-weight: 500;
            }

            li {
                margin-bottom: 15px;

                &:last-child {
                    margin-top: 30px;
                }
            }

            button {
                background-color: transparent;
                border: none;
                color: $softblack-color;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
}

.header_content {
    padding: 5px 0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 123123123;
    background: rgba(255, 255, 255, .9);
    box-shadow: 0 8px 32px 0 rgba(180, 180, 180, 0.37);
    backdrop-filter: blur(13.5px);
    -webkit-backdrop-filter: blur(13.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    .header_lg_buttons {
        @media (min-width: $medium) and (max-width: $large) {
            padding: 0 !important;
        }
    }

    @media screen and (max-width: $medium) {
        display: none;
    }

    .navigation-list {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            margin: 0 13px;
            font-size: 14px;

            a {
                color: $softblack-color;
                text-decoration: none;
            }
        }
    }

    .primary-btn {
        letter-spacing: .7px;
        font-size: 15px;
        padding: 10px 20px;
    }
}

/* END HEADER */

/* START BANNER */

.banner_content {
    padding: 125px 0 75px;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%);
    color: $white-color;

    @media screen and (max-width: $medium) {
        padding: 50px 0;
    }

    @media (min-width: $medium) and (max-width: $large) {
        padding: 100px 0 75px;
    }

    .banner_video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    h1 {
        position: relative;
        font-size: 64px;
        font-weight: 600;
        width: fit-content;

        &::after {
            content: "";
            background-image: url("../assets/images/line/main-gradient-line.svg");
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            height: 10px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;

            @media screen and (max-width: $medium) {
                display: none;
            }

            @media (min-width: $medium) and (max-width: $large) {
                display: none;
            }
        }

        @media screen and (max-width: $medium) {
            font-size: 32px;
        }
    }

    .banner_sliding_text {
        font-size: 48px;

        @media screen and (max-width: $medium) {
            font-size: 24px;
        }
    }

    .banner_text {
        font-size: 20px;
        line-height: 27px;

        @media screen and (max-width: $medium) {
            width: 100% !important;
            font-size: 16px;
            margin-top: 75px !important;
        }
    }

    .banner_overlay_logo {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        z-index: -1;

        img {
            filter: grayscale(1);
            opacity: .05;
        }
    }

    .slider-container {
        background-color: $black-color;
        border-radius: 10px;
        padding: 0 10px;
        margin-top: 100px;

        .slider-item {
            background-color: $softblack-color;
            margin: 10px 5px 5px;
            padding: 20px;
            border-radius: 10px;
        }
    }
}

/* END BANNER */

/* START SERVICES */

.mobile_servicepage_content {
    .service_card {
        height: 350px !important;
    }

    .service_card_title {
        font-size: 36px !important;
    }

    .slick-dots {
        bottom: -35px;

        li {
            &.slick-active {
                button {
                    &::before {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.services_mobile_content {
    img {
        width: 100% !important;
        border-radius: 10px;
    }

    h4 {
        font-size: 24px !important;
        margin-bottom: 27px !important;
    }

    .card {
        height: 290px !important;
    }

    .slick-dots {
        li {
            button {
                &::before {
                    color: #fff !important;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .slick-dots {
        bottom: -30px;
    }
}

.services_content {
    background-color: $black-color;
    color: $white-color;
    padding: 125px 0;
    overflow: hidden;

    @media screen and (max-width: $medium) {
        padding: 50px 0;
    }

    .panel_section {
        display: flex;
        overflow-x: hidden;
        /* Yatay kaydırma için */
        scroll-snap-type: x mandatory;

        /* Yatay kaydırmada panellerin düzgün hizalanmasını sağlar */
        .panel {
            flex: 0 0 33.3%;
            /* Her bir panel %35 genişliğinde */
            margin-right: 30px;
            /* Paneller arasında 30px boşluk */
            scroll-snap-align: start;
            /* Kaydırma sırasında panellerin hizalanmasını sağlar */
        }

        .panel:last-child {
            margin-right: 0;
            /* Son panelin sağında boşluk olmaması için */
        }
    }

    .card {
        border-radius: 15px;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 450px;
        // display: flex;
        // align-items: start;
        // justify-content: end;
        // box-sizing: border-box;
        padding: 35px 30px;

        @media screen and (max-width: $medium) {
            height: 350px;
            padding: 25px 15px;
        }

        h4 {
            z-index: 123;
            font-size: 28px;
            color: $black-color;
            margin-bottom: 40px;
        }

        &::after {
            content: "";
            position: absolute;
            width: 101%;
            height: 101%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 85%);
            left: -1px;
            bottom: -1px;
            border-radius: 15px;
        }
    }
}

/* END SERVICES */

/* START TECHNOLOGIES */

.technologies_content {
    padding: 125px 0;
    background-color: $main-color;

    @media screen and (max-width: $medium) {
        padding: 50px 0;
    }

    .technologies_tab_content {
        margin-top: 75px;
        text-align: center;
    }

    .react-tabs__tab-list {
        border: none;
        margin: 0;

        .react-tabs__tab {
            border: none;
            font-size: 24px;
            padding: 10px 30px;
            background-color: #f3f3f3;
            border-radius: 50px;
            margin: 0 8px;

            @media screen and (max-width: $medium) {
                font-size: 20px;
                margin: 5px 8px;
            }

            &:focus {
                display: none;
            }
        }

        .react-tabs__tab.react-tabs__tab--selected {
            background-color: $secondary-color;
            color: $white-color;
        }
    }

    .react-tabs__tab-panel {
        margin-top: 100px;

        p {
            font-size: 20px;
            margin-top: 23px;
        }
    }
}

/* END TECHNOLOGIES */

/* START WHY US */


.whyus_content {
    background-color: $black-color;
    color: $white-color;
    padding: 125px 0;

    @media screen and (max-width: $medium) {
        padding: 75px 0;
    }

    .whyus_card_content {
        margin-top: 75px;
    }

    .whyus_card {
        background-color: $softblack-color;
        padding: 50px;
        border-radius: 25px;
        margin-top: 25px;

        @media screen and (max-width: $medium) {
            padding: 30px;
        }

        @media (min-width: $medium) and (max-width: $large) {
            padding: 30px;
        }

        h5 {
            font-size: 36px;
            letter-spacing: .4px;
            margin-bottom: 20px;

            @media screen and (max-width: $medium) {
                font-size: 32px;
            }

            @media (min-width: $medium) and (max-width: $large) {
                font-size: 32px;
            }
        }

        p {
            font-size: 20px;

            @media screen and (max-width: $medium) {
                font-size: 16px;
                margin-bottom: 25px !important;
            }
        }

        .col-lg-4 {
            text-align: center !important;

            &.text-end {
                text-align: center !important;

                img {
                    width: 65% !important;

                    @media screen and (max-width: $medium) {
                        position: absolute;
                        right: 5px;
                        top: 35px;
                        opacity: .05;
                    }

                    @media (min-width: $medium) and (max-width: $large) {
                        width: 100% !important;
                    }
                }
            }

            img {
                width: 65% !important;

                @media screen and (max-width: $medium) {
                    position: absolute;
                    right: 5px;
                    top: 35px;
                    opacity: .05;
                }

                @media (min-width: $medium) and (max-width: $large) {
                    width: 100% !important;
                }
            }
        }
    }
}

/* END WHY US */

/* START CONTACT FORM */

.contactform_content {
    margin-top: 75px;

    .contactform_leftside {
        background-color: $black-color;
        padding: 150px 115px;
        color: $white-color;

        h5 {
            font-size: 44px;
            letter-spacing: .4px;
        }

        .contactform_text {
            font-size: 17px;
            letter-spacing: .2px;
        }
    }

    .contactform_rightside {
        padding: 50px 30px;
        background-color: #131313;
        border-radius: 15px;
        z-index: 123;

        @media screen and (max-width: $medium) {
            padding: 40px 20px;
        }

        .contact_input {
            width: 100%;
            border: none;
            border-bottom: 1px solid rgb(136, 136, 136);
            border-radius: 0;
            padding: 10px;
            outline: none;
            background-color: transparent;
            color: $main-color;

            &.contact_input_disabled {
                opacity: .2;
            }

            &:focus {
                border-bottom: 1px solid $secondary-color;
            }
        }

        .contact_label {
            position: absolute;
            left: 10px;
            top: 10px;
            pointer-events: none;
            color: rgb(136, 136, 136);
            font-size: 16px;
            transition: all 0.3s ease;

            &.contact_label_disabled {
                opacity: .2;
            }
        }

        .contact_text_area {
            min-height: 125px;
        }
    }
}

/* END CONTACT FORM */

/* START OFFER FORM */

.offerform_content {
    .form-select {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: white;
        color: #333;
        transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

        /* Hover durumunda stil */
        &:hover {
            border-color: $secondary-color;
        }

        /* Focus durumunda stil */
        &:focus {
            outline: none;
            border-color: $secondary-color;
        }

        /* Option'lar için özel stil */
        option {
            padding: 20px !important;
            color: rgb(136, 136, 136);
        }

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffa500;
            /* Turuncu renk */
            border-radius: 10px;
            border: 3px solid #f1f1f1;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #ff8c00;
            /* Hover durumunda koyulaşan turuncu */
        }
    }


    .offerform {
        padding: 50px;

        @media screen and (max-width: $medium) {
            padding: 30px 20px;
        }
    }

    .contact_label_checkbox {
        color: rgb(136, 136, 136);
    }

    .checkbox-wrapper-32 {
        --size: 20px;
        --border-size: 2px;

        position: relative;
    }

    .checkbox-wrapper-32 *,
    .checkbox-wrapper-32 *::after,
    .checkbox-wrapper-32 *::before {
        box-sizing: border-box;
    }

    .checkbox-wrapper-32 input[type="checkbox"] {
        display: inline-block;
        vertical-align: middle;
        opacity: 0;
    }

    .checkbox-wrapper-32 input[type="checkbox"],
    .checkbox-wrapper-32 label::before {
        width: var(--size);
        height: var(--size);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .checkbox-wrapper-32 label {
        display: inline-block;
        position: relative;
        padding: 0 0 0 calc(var(--size) + 7px);
    }

    .checkbox-wrapper-32 label::before {
        content: '';
        border: var(--border-size) solid rgb(136, 136, 136);
        opacity: 0.7;
        transition: opacity 0.3s;
    }

    .checkbox-wrapper-32 input[type="checkbox"]:checked+label::before {
        opacity: 1;
    }

    .checkbox-wrapper-32 svg {
        position: absolute;
        top: calc(40%);
        left: var(--border-size);
        width: calc(var(--size) - (var(--border-size) * 2));
        height: calc(var(--size) - (var(--border-size) * 2));
        margin-top: calc(var(--size) / -2);
        pointer-events: none;
    }

    .checkbox-wrapper-32 svg path {
        stroke-width: 0;
        fill: none;
        transition: stroke-dashoffset 0.2s ease-in 0s;
    }

    .checkbox-wrapper-32 svg path+path {
        transition: stroke-dashoffset 0.2s ease-out 0.2s;
    }

    .checkbox-wrapper-32 input[type="checkbox"]:checked~svg path {
        stroke-dashoffset: 0;
        stroke-width: calc(var(--size) / 2);
    }

    label {
        display: flex;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        margin-bottom: 0.375em;
        margin-right: 3px;

        /* Accessible outline */
        /* Remove comment to use */
        /*
          &:focus-within {
              outline: .125em solid $primary-color;
          }
        */
        input {
            position: absolute;
            left: -9999px;

            &:checked+span {
                background-color: mix($secondary-color, $primary-color, 84%);
                color: $white-color;
                border: 1px solid $main-color;

                &:before {
                    box-shadow: inset 0 0 0 0.4375em $primary-color;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
            padding: 5px 15px;
            border-radius: 99em; // or something higher...
            transition: 0.25s ease;
            color: rgb(136, 136, 136);
            border: 1px solid rgb(136, 136, 136);

            &:hover {
                background-color: mix($secondary-color, $primary-color, 84%);
                color: $white-color;
            }
        }
    }

    .offerform_title {
        color: rgb(136, 136, 136);
    }

    .form-select {
        background-color: transparent;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid rgb(136, 136, 136);
        color: rgb(136, 136, 136);
    }

    .offerform_lastsection {
        margin-top: 80px;
    }
}

/* END OFFER FORM */

/* START CTA */

.cta_content {
    border-radius: 20px;
    background: #fff;
    padding: 125px 0 150px;
    margin: 0 30px 100px;

    @media screen and (max-width:$medium) {
        margin: 0 15px 100px !important;
        padding: 50px 0 150px;
    }

    .cta_image {
        @media screen and (max-width: $medium) {
            text-align: center;
        }
    }

    .cta_title {
        font-size: 84px;

        @media screen and (max-width: $medium) {
            font-size: 48px;
            text-align: center;
        }
    }

    img {
        @media screen and (max-width: $medium) {
            width: 75% !important;
            margin: 25px 0 0 0;
        }
    }

    .cta_subtitle {
        font-size: 64px;

        @media screen and (max-width: $medium) {
            font-size: 48px;
            margin-top: 20px;
        }
    }

    .cta_text {
        color: #434343;
        position: absolute;
        bottom: 35px;
        width: 100%;
        left: 0;

        @media screen and (max-width: $medium) {
            padding: 0 20px;
        }
    }

    .primary-btn {
        font-size: 22px;
        font-weight: 500;
    }
}

/* END CTA */

/* START FOOTER */

.footer_content {
    background-color: $main-color;
    padding: 75px 0 25px;

    .footer_mobile_content {
        @media screen and (max-width: $medium) {
            text-align: center;
        }
    }

    .copyright_mobile {
        @media screen and (max-width: $medium) {
            text-align: center;
        }
    }

    @media screen and (max-width: $medium) {
        padding: 50px 0 25px;
    }

    h5 {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .footer_contact {
        div {
            margin-top: 10px;
        }
    }

    li {
        margin-top: 10px;
    }

    a {
        color: $softblack-color;
        text-decoration: none;
    }

    .contact_socialmedia img {
        border: 1px solid $softblack-color;
        width: 46px;
        height: 46px;
        padding: 10px;
        margin-right: 10px;
        border-radius: 5px;
    }
}

/* END FOOTER */

/* START ABOUT PAGE */

.subpage_banner_content {
    padding: 125px 0;
    position: relative;
    background: linear-gradient(0deg, rgb(248 248 248) 0%, rgba(0, 0, 0, 0) 40%);

    @media screen and (max-width: $medium) {
        padding: 45px 0;
    }

    .banner_video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    h1 {
        font-size: 24px;
        font-weight: 200;

        @media screen and (max-width: $medium) {
            font-size: 20px;
        }
    }

    .subpage_subtitle {
        font-size: 54px;
        font-weight: 600;
        letter-spacing: .4px;

        @media screen and (max-width: $medium) {
            font-size: 32px;
        }
    }

    .subpage_banner_image {
        position: absolute;
        width: 600px;
        right: -12%;
        top: 50%;
        transform: translate(0, -50%);
    }
}

.subpage_about_content {
    background-color: $main-color;
    padding: 125px 0 50px 0;
    overflow-x: hidden;

    @media screen and (max-width: $medium) {
        padding: 50px 0 25px 0;
    }

    @media (min-width: $medium) and (max-width: $large) {
        padding: 50px 0 25px 0;
    }

    .subpage_aboutus_image {
        margin: 160px 0;

        @media screen and (max-width: $medium) {
            margin: 50px 0;
        }

        img {
            border-radius: 10px;
        }
    }

    .subpage_about_title {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: .3px;
    }

    .subpage_about_text {
        font-size: 17px;
    }

    .subpage_about_leftimage {
        position: absolute;
        left: -30%;
        top: -30px;

        @media screen and (max-width: $medium) {
            display: none !important;
        }
    }

    .subpage_about_rightimage {
        position: absolute;
        left: 60%;
        top: -20%;

        @media screen and (max-width:$medium) {
            display: none;
        }

        @media (min-width: $medium) and (max-width:$large) {
            top: -5%;
        }
    }

    .subpage_aboutus_content2 {
        margin-bottom: 75px;

        @media screen and (max-width:$medium) {
            margin-bottom: 0 !important;
        }
    }
}

.subpage_aboutus_cta {
    padding: 75px 0;

    .cta_content {
        margin: 0 30px 25px;
    }
}

/* END ABOUT PAGE */

/* START SERVICES PAGE */

.subpage_services_text_content {
    background-color: $main-color;
    padding: 125px 0;

    @media screen and (max-width: $medium) {
        padding: 60px 0;
    }

    .subpage_services_content_subtitle {
        font-size: 16px;
    }

    .subpage_services_content_title {
        font-size: 46px;
        font-weight: 600;
        letter-spacing: .3px;

        @media screen and (max-width: $medium) {
            font-size: 32px;
        }
    }

    .subpage_services_leftimage {
        position: absolute;
        left: 0;
        top: 0;
    }

    .subpage_services_cards {
        margin-top: 75px;

        @media screen and (max-width: $medium) {
            margin-top: 25px;
        }
    }

    .service_card {
        background-repeat: no-repeat;
        height: 400px;
        border-radius: 25px;
        transition: all .3s ease;

        &:hover {
            scale: 1.03;
        }

        .service_card_title {
            font-size: 42px;
            padding: 30px;
        }

        &.service_card1 {
            .service_card_title {
                color: $white-color !important;
            }
        }

        &.service_card2 {
            background-position: 50% 0;

            .service_card_title {
                color: $white-color;
            }
        }

        &.service_card3 {
            height: 600px;

            .service_card_title {
                color: $white-color;
            }
        }

        &.service_card4 {
            height: 600px;
            display: flex;
            align-items: end;

            .service_card_title {
                color: #fff;
            }
        }

        &.service_card5 {
            height: 600px;

            .service_card_title {
                color: $white-color;
            }
        }
    }
}

.subpage_services_page {
    .cta_content {
        margin: 100px 30px;

        @media screen and (max-width: $medium) {
            margin: 50px 15px 50px !important;
        }
    }
}

/* END SERVICES PAGE */

/* START CONTACT PAGE */

.subpage_contact_content {
    background-color: $main-color;
    padding: 125px 0;

    @media screen and (max-width: $medium) {
        padding: 60px 0;
    }

    .subpage_contact_content_subtitle {
        font-size: 16px;
    }

    .subpage_contact_content_title {
        font-size: 46px;
        font-weight: 600;
        letter-spacing: .3px;

        @media screen and (max-width: $medium) {
            width: 100% !important;
            font-size: 32px;
        }

        @media (min-width: $medium) and (max-width: $large) {
            width: 100% !important;
            font-size: 36px;
        }
    }

    .subpage_contact_logo {
        position: absolute;
        top: 55%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;

        img {
            filter: grayscale(1);
            opacity: 0.08;
        }
    }
}

/* END CONTACT PAGE */

/* START SERVICE DETAIL PAGE */

.subpage_servicedetail_content {
    overflow-x: hidden;

    .subpage_servicedetail_banner {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        content: "";
        width: 100%;
        height: 250px;
    }

    .subpage_servicedetail_bannerbottom {
        padding: 75px 0;

        @media screen and (max-width: $medium) {
            padding: 40px 15px;
        }

        .subpage_servicedetail_title {
            color: $white-color;
            font-size: 42px;
            font-weight: 600;
            letter-spacing: .4px;
        }

        .subpage_servicedetail_paragraph {
            color: $main-color;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
        }

        .subpage_servicedetail_subtitles {
            color: $main-color;
            font-size: 16px;
            font-weight: 500;
            color: $secondary-color;
        }

        .subpage_servicedetail_descriptions {
            color: $white-color;
            font-size: 32px;

            @media screen and (max-width:$medium) {
                font-size: 24px;
            }
        }
    }

    .subpage_servicedetail_contentarea {
        padding: 100px 0;
        background-color: $main-color;

        @media screen and (max-width: $medium) {
            padding: 50px 0;
        }

        img {
            border-radius: 20px;
        }

        h3 {
            font-size: 32px;
        }

        h5 {
            color: $softblack-color;
            font-weight: 600;
        }

        .subpage_servicedetail_contentarea_item {
            margin-bottom: 30px;
        }
    }

    .subpage_tools_technologies_content {
        padding: 75px 0;

        h3 {
            color: $white-color;
            font-size: 48px;
            letter-spacing: .4px;
        }

        h5 {
            color: $main-color;
            font-size: 26px;
        }

        .tools_content {
            display: flex;
            flex-wrap: wrap;

            .tools_content_item {
                background-color: transparent;
                color: $main-color;
                width: fit-content;
                padding: 6px 24px;
                border-radius: 50px;
                font-weight: 500;
                border: 2px solid $main-color;
                font-size: 18px;
                margin-right: 10px;
            }
        }
    }
}

/* END SERVICE DETAIL PAGE */